import * as React from "react"
import Layout from "../../components/layout"
import Servicelinks from "../../components/servicelinks"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Helmet from 'react-helmet'
import useIntersection from '../../components/useIntersection'; // import the custom hook
import classNames from 'classnames';

const Section = ({ id, className, children }) => {
  const [ref, isIntersecting, hasBeenInView] = useIntersection({
    root: null, // Use the viewport as the root
    rootMargin: '0px 0px -40% 0px', // Adjust this to place the top of the section at the center
    threshold: 0 // Trigger when the top of the element is in view
  });

  return (
    <section
      id={id}
      ref={ref}
      className={classNames(className, { 'not-in-view': !isIntersecting && !hasBeenInView })}
    >
      {children}
    </section>
  );
};

const WebDev = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'service-page'
    }}
/>
  <Layout pageTitle="WebDev">
    <section id="main">
      <div id="service-intro">
        <div className="left-col">
          <h1><span className="revealblack">Web Development Melbourne</span></h1>
          <p><span className="revealblack">With web development anything is possible, flexible, functional, scalable and ready to perform for your online business and deliver your visitors with a seamless user experience.</span></p>
          <a className="button sendusemail" href="#sendusemail"><span className="seemore"><i>&#xe801;</i></span>Send us an email</a>
        </div>
        <div className="right-col">
            <img className="picture" src={'/img/services/web-development-melbourne-tobstar2.jpg'} alt="Web Development Melbourne" />
        </div>
      </div>
    </section>

    <Section id="service-content">
      <div id="inner-content">

        <p><strong>We have range of web development skills and knowledge to offer our clients, those include:</strong></p>

        <ul>
          <li>Mobile and tablet responsive websites</li>          
          <li>Website optismised for the latest browsers and devices</li> 
          <li>Strong knowledge in Git (version control), PHP, Javascript and MySQL</li> 
          <li>Attention to detail on both code and design</li> 
          <li>Custom websites built on the any of CMS such as <AniLink cover to="/capabilities/wordpress-development-melbourne" bg="#00ccd4" direction="right" duration={0.76}>WordPress</AniLink> or <AniLink cover to="/capabilities/ecommerce-development-melbourne" bg="#00ccd4" direction="right" duration={0.76}>Magento</AniLink></li> 
      <li>Strong knowledge of how to setup a <AniLink cover to="/capabilities/private-server-web-hosting-melbourne" bg="#00ccd4" direction="right" duration={0.76}>private server</AniLink> and Terminal (SSH)</li> 
          <li>Experience with the Custom Post Types and Advanced Custom Fields plugin with <AniLink cover to="/capabilities/wordpress-development-melbourne" bg="#00ccd4" direction="right" duration={0.76}>WordPress</AniLink></li> 
        </ul>

        <p><strong>Our basic of web development process involves:</strong></p>

        <ul>
          <li>Specification and Requirements</li>
          <li>Provide <AniLink cover to="/capabilities/private-server-web-hosting-melbourne" bg="#00ccd4" direction="right" duration={0.76}>hosting or private server</AniLink> solutions</li>
          <li>Back End Development</li>
          <li>Front End Development</li>
          <li>Responsive Design and Development</li>
          <li>Browser Testing</li>
          <li>Search Engine Optimisation (SEO)</li>
        </ul>

        <p>Those are also involved with project management from requirements, wireframes, concept designs to provide preview of flat design prior coding into HTML and render on web browser. Tobstar builds site in a local development environment with Git (version control) and deploy to staging environment on our server that will be shared for review and work in process prior to deploy of production environment.</p>

        <p>We offer reasonable <AniLink cover to="/capabilities/private-server-web-hosting-melbourne" bg="#00ccd4" direction="right" duration={0.76}>hosting solutions</AniLink> to clients with our reseller hosting, which we use to manage web hosting on behalf of our clients. With personal and fast support from the hosting company this can provide some cost savings for web development and on-going maintenance and optimised for fast performance websites. Please contact us to inquire for a hosting solution package to meet your website requirements and we also offer regular backup (daily) and restorations.</p>

        <p>Our prebuilt and purchased <AniLink cover to="/capabilities/wordpress-development-melbourne" bg="#00ccd4" direction="right" duration={0.76}>WordPress</AniLink> themes deliver a cost effective solution for small businesses. Do you have a web development project? Send us brief details via email and we will be in touch.</p>

      </div>
      <Servicelinks></Servicelinks>
    </Section>
</Layout>

  </>
  )
}

export default WebDev


export const Head = () => (
  <>
    <title>Web Development Melbourne - Tobstar</title>
<meta name="description" content="With web development anything is possible, flexible, functional, scalable and ready to perform for your online business and deliver your visitors with a seamless user experience." />
  </>
)